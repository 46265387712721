import * as React from "react";

export function Delete(props) {
  return (
    <svg
      width={18}
      height={19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 14a.75.75 0 01-.75-.75V8a.75.75 0 011.5 0v5.25a.75.75 0 01-.75.75zM9.75 13.25a.75.75 0 001.5 0V8a.75.75 0 00-1.5 0v5.25z"
        fill="#D7431D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.115 3.191L5.55 4.25H3a.75.75 0 000 1.5h.042l.616 10.544a.75.75 0 00.748.706h9.188a.75.75 0 00.748-.706l.615-10.544H15a.75.75 0 000-1.5h-2.55l-.565-1.059A2.25 2.25 0 009.9 2H8.1a2.25 2.25 0 00-1.985 1.191zM8.1 3.5a.75.75 0 00-.662.397l-.188.353h3.5l-.188-.353A.75.75 0 009.9 3.5H8.1zm5.355 2.25h-8.91l.569 9.75h7.772l.569-9.75z"
        fill="#D7431D"
      />
    </svg>
  );
}
