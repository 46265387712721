/**
 * @file index.jsx
 * @author Rishikesh
 * @date 2024-10-10
 * @description Navigation routings of this app.
 */
import { createBrowserRouter } from "react-router-dom";
import ErrorBoundary from "../components/common/ErrorBoundary";
import AppLayout from "../components/layout/AppLayout";
import MainLayout from "../components/layout/MainLayout";
import AdminLayout from "../components/layout/AdminLayout";
import AdminRoute from "./AdminRoute";

import HomePage from "../pages/user/home/HomePage";
import ProductListingPage from "../pages/user/productlisting/ProductListingPage";
import CustomizePage from "../pages/user/customize/CustomizePage";
import GuidelinesPage from "../pages/user/guidelines/GuidelinesPage";
import SummaryPage from "../pages/user/summary/SummaryPage";
import CanvasPage from "../pages/user/canvas/CanvasPage";
import CartPage from "../pages/user/cart/CartPage";
import LoginPage from "../pages/admin/auth/LoginPage";
import Dashboard from "../pages/admin/dashboard/Dashboard";
import ProductDetails from "../pages/admin/productdetails/ProductDetails";
import Orders from "../pages/user/orders/Orders";
import OrderDetails from "../pages/user/orderdetails/OrderDetails";
import PaymentStatus from "../pages/user/paymentstatus/PaymentStatus";

// Router configuration
export const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <AppLayout />,
      children: [
        {
          element: (
            <ErrorBoundary>
              <MainLayout />
            </ErrorBoundary>
          ),
          children: [
            { index: true, element: <HomePage /> },
            { path: "productlisting", element: <ProductListingPage /> },
            { path: "customize/:id", element: <CustomizePage /> },
            { path: "guidelines", element: <GuidelinesPage /> },
            { path: "summary", element: <SummaryPage /> },
            { path: "canvas", element: <CanvasPage /> },
            { path: "cart", element: <CartPage /> },
            { path: "orders", element: <Orders /> },
            { path: "order-details/:id", element: <OrderDetails /> },
            {
              path: "paymentstatus/:orderstatus/:orderuuid",
              element: <PaymentStatus />,
            },
          ],
        },
        {
          path: "admin",
          element: <AdminLayout />,
          children: [
            { index: true, element: <LoginPage /> },
            {
              element: <AdminRoute />,
              children: [
                { path: "dashboard", element: <Dashboard /> },
                { path: "product-details/:id", element: <ProductDetails /> },
              ],
            },
          ],
        },
      ],
    },
  ],
  {
    basename: process.env.REACT_APP_BASE_NAME,
  }
);
