/**
 * @file CanvasHeader.jsx
 * @author Rishikesh
 * @date 2024-10-16
 * @description Component of the Canvas section to show the header part.
 */
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";

export const CanvasHeader = ({
  handleBack,
  handleSaveAndContinue,
  product,
}) => {
  const padding = useResponsivePadding();
  const currency = useSelector((state) => state.settings.currency);

  return (
    <Stack
      display="flex"
      direction="row"
      bgcolor="background.paper"
      py={1.5}
      px={padding}
      maxHeight={52}
      justifyContent="space-between"
    >
      {/* Back button */}
      <Button
        variant="outlined"
        color="success"
        sx={{ borderRadius: "8px", py: 1.5 }}
        size="small"
        onClick={handleBack}
      >
        Back
      </Button>
      <Stack display={{ xs: "none", md: "flex" }} direction="row" spacing={4}>
        <Stack display="flex" alignItems={"end"} direction="row" spacing={1}>
          {/* Product details */}
          <Stack display="flex" alignItems={"end"} direction="row" spacing={2}>
            <Typography fontWeight={600}>{product?.name}</Typography>
            {product?.price && (
              <Typography fontWeight={600}>
                {currency} {product.price}
              </Typography>
            )}
          </Stack>
          <Typography fontSize={14}>/ piece</Typography>
        </Stack>
      </Stack>
      {/* Save button */}
      <Button
        variant="outlined"
        color="success"
        sx={{ borderRadius: "8px", py: 1.5 }}
        onClick={handleSaveAndContinue}
      >
        Save and Continue
      </Button>
    </Stack>
  );
};
