/**
 * @file Header.jsx
 * @author Rishikesh
 * @date 2024-10-10
 * @description Admin Header component of this app.
 */
import React from "react";
import {
  AppBar,
  Toolbar,
  Stack,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Icons, Images } from "../../assets";
import { adminSignOut } from "../../store/features/authSlice";
import { useResponsivePadding } from "../hooks/useResponsivePadding";

const AdminHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const padding = useResponsivePadding();

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: 1251,
        backgroundColor: "primary.main",
        boxShadow: "none",
      }}
    >
      <Toolbar
        style={{
          justifyContent: "space-between",
          paddingLeft: padding,
          paddingRight: padding,
        }}
      >
        <Stack direction="row" alignItems="center" my={1}>
          <IconButton onClick={() => navigate("/")}>
            <img src={Images.logo} style={{ height: "40px" }} alt="logo" />
          </IconButton>
        </Stack>
        <Stack>
          <Button
            sx={{ textTransform: "none" }}
            color="white"
            startIcon={<Icons.Logout />}
            onClick={() => dispatch(adminSignOut())}
          >
            <Typography>Logout</Typography>
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default AdminHeader;
