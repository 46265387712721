/**
 * @file ProductListingPage.jsx
 * @author Rishikesh
 * @date 2024-10-11
 * @description List of the products
 */
import React from "react";

const ProductListingPage = () => {
  return <div>ProductListingPage</div>;
};

export default ProductListingPage;
