/**
 * @file Menu.jsx
 * @author Rishikesh
 * @date 2024-10-11
 * @description List of menu
 */
import { Button, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useResponsivePadding } from "../hooks/useResponsivePadding";

export const Menu = ({ menuList }) => {
  const navigate = useNavigate();
  const padding = useResponsivePadding();

  return (
    <Stack
      display="flex"
      direction="row"
      bgcolor="background.paper"
      py={1}
      px={padding}
      maxHeight={52}
      spacing={4}
      sx={{
        overflowX: "auto",
        whiteSpace: "nowrap",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      {menuList.map((item) => (
        <Button
          key={item.productuuid}
          sx={{
            textTransform: "none",
            minWidth: "fit-content",
          }}
          onClick={() =>
            navigate(`/customize/${item.seourl}`, {
              state: { product: item },
            })
          }
        >
          {item.menuname}
        </Button>
      ))}
    </Stack>
  );
};
