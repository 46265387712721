/**
 * @file CustomizePage.jsx
 * @author Rishikesh
 * @date 2024-10-11
 * @description Page for customize the selected product.
 */
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  Divider,
  Grid2,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Icons } from "../../../assets";
import { useFetchData } from "../../../components/hooks/useFetchData";
import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";
import CustomSkeleton from "../../../components/common/CustomSkeleton";

const CustomizePage = () => {
  const location = useLocation();
  const product = location.state?.product;
  const navigate = useNavigate();
  const padding = useResponsivePadding();
  const { fetchData, loading } = useFetchData();

  const currency = useSelector((state) => state.settings.currency);

  const [minLoading, setMinLoading] = useState(true);
  const [productDetails, setProductDetails] = useState([]);
  const [selectedSpecification, setSelectedSpecification] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [productVariationInfo, setProductVariationInfo] = useState([]);

  React.useEffect(() => {
    if (product === undefined) {
      navigate("/", { replace: true });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const getProductDetails = async () => {
    if (!product) return;
    const result = await fetchData(`user/productvariations/${product.seourl}`);
    if (result?.code === 200) {
      setProductDetails(result.data);

      // Set the first variation of each specification as the default
      const initialSpecifications = result.data?.reduce((acc, item) => {
        if (item.variations?.length > 0) {
          const firstVariation = item.variations[0];
          acc[item.varianceid] = {
            variancename: item.variancename,
            varianceid: item.varianceid,
            variationname: firstVariation.variationname,
            variationid: firstVariation.variationid,
          };
        }
        return acc;
      }, {});
      setSelectedSpecification(initialSpecifications);
    }
  };

  const getProductvariationinfo = async () => {
    if (!product) return;
    setProductVariationInfo([]);
    const productData = Object.keys(selectedSpecification).reduce(
      (acc, key) => {
        const { varianceid, variationid } = selectedSpecification[key];
        acc[varianceid] = variationid;
        return acc;
      },
      {}
    );

    if (Object.keys(productData).length === 0) return;

    const body = { productdata: productData, productuuid: product.productuuid };
    const result = await fetchData("user/productvariationinfo", {
      method: "post",
      body: JSON.stringify(body),
    });
    if (result?.code === 200) {
      setProductVariationInfo(result.data);
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMinLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getProductDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  React.useEffect(() => {
    getProductvariationinfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpecification]);

  // Handle increment function
  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  // Handle decrement function
  const handleDecrement = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const handleSelect = (variance, variation) => {
    setSelectedSpecification((prev) => ({
      ...prev,
      [variance.varianceid]: {
        variancename: variance.variancename,
        varianceid: variance.varianceid,
        variationname: variation.variationname,
        variationid: variation.variationid,
      },
    }));
  };

  const isSelected = (varianceid, variationid) =>
    selectedSpecification[varianceid]?.variationid === variationid;

  const isLoading = minLoading || loading["user/productvariationinfo"];

  return (
    <Box px={padding} py={4}>
      {/* Breadcrums to go back to the previews pages */}
      <Breadcrumbs>
        <Link onClick={() => navigate("/", { replace: true })}>Home</Link>
        <Typography fontWeight={500} color="primary.main">
          Customize
        </Typography>
      </Breadcrumbs>
      <Stack display="flex" direction="row" mt={3} spacing={3}>
        {!matchDownSM && (
          <Box width={{ sm: 0, md: "50%" }} mb={1}>
            {/* Product details */}
            <Stack
              display="flex"
              direction="row"
              justifyContent="space-between"
            >
              <Typography variant="h5" fontWeight={600}>
                {product.productname}
              </Typography>
              {productVariationInfo?.[0]?.price && (
                <Stack direction="row" alignItems="end">
                  <Typography variant="h5" color="error" fontWeight={600}>
                    {`${currency} ${productVariationInfo[0].price || ""}`}
                  </Typography>
                  <Typography>/ piece</Typography>
                </Stack>
              )}
            </Stack>
            <Divider />
            {/* Listing of specification of the product */}
            <Box mt={1}>
              <Typography fontWeight={500} color="success">
                Choose specifictions
              </Typography>
              {productDetails.map((item) => (
                <Box mt={1} py={1} key={item.varianceid}>
                  <Typography fontWeight={500}>{item.variancename}</Typography>
                  <Grid2 container mt={1} spacing={2}>
                    {item.variations.map((items) => (
                      <Grid2 key={items.variationid} size="grow">
                        <Card
                          sx={{
                            boxShadow: "none",
                            borderRadius: "8px",
                            border: `1px solid ${
                              isSelected(item.varianceid, items.variationid)
                                ? "#319795"
                                : "#A8ACAF"
                            } `,
                            backgroundColor: isSelected(
                              item.varianceid,
                              items.variationid
                            )
                              ? "#EAF5F4"
                              : "background.paper",
                          }}
                        >
                          <CardActionArea
                            onClick={() => handleSelect(item, items)}
                          >
                            <Stack
                              display="flex"
                              direction="row"
                              sx={{ px: 4, py: 2 }}
                              alignItems="center"
                              justifyContent="center"
                              spacing={2}
                            >
                              <Typography>{items.variationname}</Typography>
                              {isSelected(
                                item.varianceid,
                                items.variationid
                              ) && <Icons.Checkmark />}
                            </Stack>
                          </CardActionArea>
                        </Card>
                      </Grid2>
                    ))}
                  </Grid2>
                </Box>
              ))}
              <Box mt={1} py={1}>
                <Typography fontWeight={500}>Quantity</Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  mt={1}
                >
                  {/* Decrement Button */}
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "8px",
                      width: "40%",
                      border: "1px solid #A8ACAF",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CardActionArea onClick={handleDecrement}>
                      <Typography
                        variant="h4"
                        sx={{ px: 4, py: 1 }}
                        color="secondary.main"
                      >
                        -
                      </Typography>
                    </CardActionArea>
                  </Card>

                  {/* Quantity Display */}
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    color="primary.main"
                    sx={{ minWidth: 32, textAlign: "center" }}
                  >
                    {quantity}
                  </Typography>

                  {/* Increment Button */}
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "8px",
                      border: "1px solid #A8ACAF",
                      width: "40%",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CardActionArea onClick={handleIncrement}>
                      <Typography
                        variant="h4"
                        sx={{ px: 4, py: 1 }}
                        color="secondary.main"
                      >
                        +
                      </Typography>
                    </CardActionArea>
                  </Card>
                </Stack>
              </Box>

              <Button
                fullWidth
                sx={{
                  mt: 2,
                  py: 1,
                  fontSize: 20,
                  textTransform: "none",
                  borderRadius: "8px",
                }}
                variant="contained"
                disabled={isLoading}
                onClick={() =>
                  navigate("/guidelines", {
                    state: {
                      productDetails: {
                        product,
                        selectedSpecification,
                        quantity,
                        productVariationInfo,
                      },
                    },
                  })
                }
              >
                Next
              </Button>
            </Box>
          </Box>
        )}
        {/* Image of the product specific varients */}
        <Box
          bgcolor="background.paper"
          flex={1}
          minHeight={{ xs: 380, sm: 480, md: 580, lg: 680 }}
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          borderRadius={2}
        >
          {/* Product Image */}
          {isLoading ? (
            <CustomSkeleton sx={{ my: 2 }} />
          ) : productVariationInfo?.[0]?.image ? (
            <img
              src={productVariationInfo?.[0]?.image}
              alt={product.productname}
              style={{
                width: "80%",
                height: "80%",
                objectFit: "contain",
              }}
            />
          ) : (
            <Icons.Image />
          )}
        </Box>
      </Stack>
      {matchDownSM && (
        <Box mt={1}>
          <Typography fontWeight={500} color="success">
            Choose specifictions
          </Typography>
          {productDetails.map((item) => (
            <Box mt={1} py={1} key={item.varianceid}>
              <Typography fontWeight={500}>{item.variancename}</Typography>
              <Grid2 container mt={1} spacing={2}>
                {item.variations.map((items) => (
                  <Grid2 key={items.variationid} size="grow">
                    <Card
                      sx={{
                        boxShadow: "none",
                        borderRadius: "8px",
                        border: `1px solid ${
                          isSelected(item.varianceid, items.variationid)
                            ? "#319795"
                            : "#A8ACAF"
                        } `,
                        backgroundColor: isSelected(
                          item.varianceid,
                          items.variationid
                        )
                          ? "#EAF5F4"
                          : "background.paper",
                      }}
                    >
                      <CardActionArea onClick={() => handleSelect(item, items)}>
                        <Stack
                          display="flex"
                          direction="row"
                          sx={{ px: 4, py: 2 }}
                          alignItems="center"
                          justifyContent="center"
                          spacing={2}
                        >
                          <Typography>{items.variationname}</Typography>
                          {isSelected(item.varianceid, items.variationid) && (
                            <Icons.Checkmark />
                          )}
                        </Stack>
                      </CardActionArea>
                    </Card>
                  </Grid2>
                ))}
              </Grid2>
            </Box>
          ))}
          <Box mt={1} py={1}>
            <Typography fontWeight={500}>Quantity</Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              mt={1}
            >
              {/* Decrement Button */}
              <Card
                sx={{
                  boxShadow: "none",
                  borderRadius: "8px",
                  width: "40%",
                  border: "1px solid #A8ACAF",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CardActionArea onClick={handleDecrement}>
                  <Typography
                    variant="h4"
                    sx={{ px: 4, py: 1 }}
                    color="secondary.main"
                  >
                    -
                  </Typography>
                </CardActionArea>
              </Card>

              {/* Quantity Display */}
              <Typography
                variant="h5"
                fontWeight={600}
                color="primary.main"
                sx={{ minWidth: 32, textAlign: "center" }}
              >
                {quantity}
              </Typography>

              {/* Increment Button */}
              <Card
                sx={{
                  boxShadow: "none",
                  borderRadius: "8px",
                  border: "1px solid #A8ACAF",
                  width: "40%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CardActionArea onClick={handleIncrement}>
                  <Typography
                    variant="h4"
                    sx={{ px: 4, py: 1 }}
                    color="secondary.main"
                  >
                    +
                  </Typography>
                </CardActionArea>
              </Card>
            </Stack>
          </Box>

          <Button
            fullWidth
            sx={{
              mt: 2,
              py: 1,
              fontSize: 20,
              textTransform: "none",
              borderRadius: "8px",
            }}
            variant="contained"
            disabled={isLoading}
            onClick={() =>
              navigate("/guidelines", {
                state: {
                  productDetails: {
                    product,
                    selectedSpecification,
                    quantity,
                    productVariationInfo,
                    canvasDataArray: [],
                  },
                },
              })
            }
          >
            Next
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CustomizePage;
