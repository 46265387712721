/**
 * @file tempImageSlice.js
 * @author Rishikesh
 * @date 2024-11-15
 * @description Slice used to manage the temporary image in whole app.
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tempImage: [],
};

const tempImageSlice = createSlice({
  name: "tempImage",
  initialState,
  reducers: {
    setTempImage: (state, action) => {
      state.tempImage = action.payload;
    },
    clearTempImage: (state) => {
      state.tempImage = [];
    },
  },
});

export const { setTempImage, clearTempImage } = tempImageSlice.actions;
export default tempImageSlice.reducer;
