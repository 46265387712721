/**
 * @file UserAuthModalContext.jsx
 * @author Rishikesh
 * @date 2024-11-04
 * @description Context used to manage the user auth modal state in whole app.
 */
import React, { createContext, useContext, useState } from "react";

const UserAuthModalContext = createContext({
  isAuthModalOpen: false,
  openAuthModal: () => {},
  closeAuthModal: () => {},
});

export const UserAuthModalProvider = ({ children }) => {
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);

  const openAuthModal = () => setAuthModalOpen(true);
  const closeAuthModal = () => setAuthModalOpen(false);

  return (
    <UserAuthModalContext.Provider
      value={{ isAuthModalOpen, openAuthModal, closeAuthModal }}
    >
      {children}
    </UserAuthModalContext.Provider>
  );
};

export const useUserAuthModal = () => useContext(UserAuthModalContext);
