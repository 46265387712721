/**
 * @file Header.jsx
 * @author Rishikesh
 * @date 2024-10-10
 * @description Header component of this app for user.
 */
import React from "react";
import {
  AppBar,
  Toolbar,
  Stack,
  Button,
  IconButton,
  useMediaQuery,
  Menu as MuiMenu,
  MenuItem,
  Badge,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Icons, Images } from "../../assets";
import { Menu } from "./Menu";
import { useResponsivePadding } from "../hooks/useResponsivePadding";
import { useUserAuthModal } from "../context/UserAuthModalContext";
import { userSignOut } from "../../store/features/authSlice";

const Header = ({ menuList }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const padding = useResponsivePadding();
  const { openAuthModal } = useUserAuthModal();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const user = useSelector((state) => state.auth.user);
  const cart = useSelector((state) => state.cart.cart);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNameFromEmail = (email) => {
    const namePart = email.split("@")[0]; // Get the part before the '@'
    return namePart.charAt(0).toUpperCase() + namePart.slice(1); // Capitalize the first letter
  };

  const handleLogout = () => {
    dispatch(userSignOut());
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: 1251,
        backgroundColor: "primary.main",
        boxShadow: "none",
      }}
    >
      <Toolbar
        style={{
          justifyContent: "space-between",
          paddingLeft: padding,
          paddingRight: padding,
        }}
      >
        <Stack direction="row" alignItems="center" my={1}>
          <IconButton onClick={() => navigate("/")}>
            <img src={Images.logo} style={{ height: "40px" }} alt="logo" />
          </IconButton>
        </Stack>
        <Stack direction="row" alignItems="center" columnGap={1.2}>
          {matchDownMD ? (
            <>
              <IconButton
                onClick={(e) => {
                  if (!user.isAuthenticated) {
                    openAuthModal();
                  } else {
                    handleMenu(e);
                  }
                }}
              >
                <Icons.Person />
              </IconButton>
              <IconButton onClick={() => navigate("/orders")}>
                <Icons.OrderBox />
              </IconButton>
              <Badge color="secondary" badgeContent={cart.length}>
                <IconButton onClick={() => navigate("/cart")}>
                  <Icons.Cart />
                </IconButton>
              </Badge>
            </>
          ) : (
            <>
              {user.isAuthenticated ? (
                <Button
                  startIcon={<Icons.Person />}
                  color="white"
                  sx={{ textTransform: "none" }}
                  onClick={handleMenu}
                >
                  {getNameFromEmail(user.info.email)}
                </Button>
              ) : (
                <Button
                  color="white"
                  startIcon={<Icons.Person />}
                  sx={{ textTransform: "none" }}
                  onClick={() => openAuthModal()}
                >
                  Login / Sign Up
                </Button>
              )}
              <Button
                color="white"
                startIcon={<Icons.OrderBox />}
                sx={{ textTransform: "none" }}
                onClick={() => navigate("/orders")}
              >
                Orders
              </Button>
              <Badge color="secondary" badgeContent={cart.length}>
                <Button
                  color="white"
                  startIcon={<Icons.Cart />}
                  sx={{ textTransform: "none" }}
                  onClick={() => navigate("/cart")}
                >
                  Cart
                </Button>
              </Badge>
              <MuiMenu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleLogout}>
                  <Icons.Logout /> Logout
                </MenuItem>
              </MuiMenu>
            </>
          )}
        </Stack>
      </Toolbar>
      {(menuList.length !== 0 || location.pathname !== "/canvas") && (
        <Menu menuList={menuList} />
      )}
    </AppBar>
  );
};

export default Header;
