/**
 * @file CanvasPage.jsx
 * @author Rishikesh
 * @date 2024-10-15
 * @description Page for customize the product with a canvas.
 */
import { Box } from "@mui/material";
import React, { useRef } from "react";
import {
  Image as KonvaImage,
  Layer,
  Text as KonvaText,
  Rect,
  Circle,
  Stage,
  Transformer,
  Group,
} from "react-konva";
import useImage from "use-image";

export const DrawingCanvas = ({
  dimension,
  templateImage,
  textElements = [],
  imageElements = [],
  setSelectedText,
  setTexts,
  transformerRef,
  stageRef,
  setImages,
  setSelectedTool,
}) => {
  const containerRef = useRef(null);
  const [templateImg] = useImage(templateImage);

  const onShapeClick = React.useCallback(
    (e) => {
      const shape = e.target;
      transformerRef.current.nodes([shape]);
      setSelectedTool(shape.attrs.image ? "Image" : "Text");
    },
    [setSelectedTool, transformerRef]
  );

  const onBgClick = React.useCallback(() => {
    transformerRef.current.nodes([]);
    setSelectedText();
  }, [setSelectedText, transformerRef]);

  const updateImagePosition = (e, image) => {
    setImages((prevImages) =>
      prevImages.map((img) =>
        img.selectedCanvas === image.selectedCanvas
          ? { ...img, x: e.target.x(), y: e.target.y() }
          : img
      )
    );
  };

  const updateImageSize = (e, image) => {
    const node = e.target;
    const newWidth = node.width() * node.scaleX();
    const newHeight = node.height() * node.scaleY();
    node.scaleX(1);
    node.scaleY(1);

    setImages((prevImages) =>
      prevImages.map((img) =>
        img.selectedCanvas === image.selectedCanvas
          ? {
              ...img,
              x: node.x(),
              y: node.y(),
              width: newWidth,
              height: newHeight,
            }
          : img
      )
    );
  };

  const updateTextPosition = (e, text) => {
    setTexts((prevTexts) =>
      prevTexts.map((t) =>
        t.id === text.id ? { ...t, x: e.target.x(), y: e.target.y() } : t
      )
    );
  };

  const updateTextSize = (e, text) => {
    const node = e.target;
    const newFontSize = text.size * node.scaleX();
    node.scaleX(1);
    node.scaleY(1);
    setTexts((prevTexts) =>
      prevTexts.map((t) =>
        t.id === text.id
          ? {
              ...t,
              x: node.x(),
              y: node.y(),
              size: newFontSize,
            }
          : t
      )
    );
  };

  const handleTextClick = (e, text) => {
    setSelectedText(text);
    onShapeClick(e);
  };

  const renderImageElements = () =>
    imageElements.map((image, index) => (
      <KonvaImage
        key={index}
        image={image.img}
        width={image.width}
        height={image.height}
        draggable
        x={image.x}
        y={image.y}
        onClick={onShapeClick}
        onTap={onShapeClick}
        onDragEnd={(e) => updateImagePosition(e, image)}
        onTransformEnd={(e) => updateImageSize(e, image)}
      />
    ));

  const renderTextElements = () =>
    textElements.map((text) => (
      <KonvaText
        key={text.id}
        x={text.x}
        y={text.y}
        text={text.text}
        fontStyle={text.style}
        fontSize={text.size}
        fontFamily={text.font}
        fill={text.color}
        draggable={!text.locked}
        listening={!text.locked}
        onClick={(e) => handleTextClick(e, text)}
        onTap={(e) => handleTextClick(e, text)}
        onDragEnd={(e) => updateTextPosition(e, text)}
        onTransformEnd={(e) => updateTextSize(e, text)}
      />
    ));

  return (
    <Box
      flex={1}
      display="flex"
      minWidth="300px"
      minHeight="300px"
      ref={containerRef}
      position="relative"
      sx={{
        borderRadius: "8px",
        "&::-webkit-scrollbar": { display: "none" },
      }}
      justifyContent="center"
      alignItems={dimension.height > 300 ? "flex-start" : "center"}
      bgcolor="background.paper"
      overflow="auto"
    >
      {/* Canvas stage for editing */}
      <Stage ref={stageRef} width={dimension.width} height={dimension.height}>
        <Layer>
          {/* Shape based on the editing product */}
          {dimension.shape === "rectangle" ? (
            <Rect
              width={dimension.width}
              height={dimension.height}
              fill="lightgray"
              id="bg"
              onClick={onBgClick}
              onTap={onBgClick}
            />
          ) : (
            <Circle
              x={dimension.width / 2}
              y={dimension.height / 2}
              radius={dimension.radius}
              fill="lightgray"
              id="bg"
              onClick={onBgClick}
              onTap={onBgClick}
            />
          )}
          {dimension.shape === "circle" ? (
            <Group
              clipFunc={(ctx) => {
                if (dimension.shape === "circle") {
                  ctx.arc(
                    dimension.width / 2,
                    dimension.height / 2,
                    dimension.radius,
                    0,
                    Math.PI * 2
                  );
                }
              }}
            >
              {renderImageElements()}
              {renderTextElements()}
            </Group>
          ) : (
            <>
              {renderImageElements()}
              {renderTextElements()}
            </>
          )}
          {/* Transformer for resizing the elements */}
          <Transformer
            ref={transformerRef}
            anchorFill="white"
            borderStroke="white"
            anchorSize={7}
            keepRatio={false}
          />
          {/* Template for the product */}
          {templateImg && (
            <KonvaImage
              image={templateImg}
              height={dimension.height}
              width={dimension.width}
              id="template"
              listening={false}
            />
          )}
        </Layer>
      </Stage>
    </Box>
  );
};
