import * as React from "react";

export function Checkmark(props) {
  return (
    <svg
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.828 5.723a.781.781 0 01.011 1.105L8.87 14.953a.781.781 0 01-1.11.006l-3.906-3.907a.781.781 0 111.105-1.104l3.348 3.348 7.416-7.562a.781.781 0 011.105-.01z"
        fill={props.color || "#319795"}
      />
    </svg>
  );
}
