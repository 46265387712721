/**
 * @file AuthModal.jsx
 * @author Rishikesh
 * @date 2024-10-30
 * @description Modal for login and signup
 */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";

import { Icons } from "../../../assets";
import { useFetchData } from "../../../components/hooks/useFetchData";
import { userSignIn } from "../../../store/features/authSlice";
import { useUserAuthModal } from "../../../components/context/UserAuthModalContext";

const AuthModal = () => {
  const { isAuthModalOpen, closeAuthModal } = useUserAuthModal();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      email: "",
      otp: Array(6).fill(""),
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const { fetchData } = useFetchData();
  const dispatch = useDispatch();

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [timer, setTimer] = useState(300);
  const otpRefs = useRef(Array(6).fill(null));

  useEffect(() => {
    setIsOtpSent(false);
    setTimer(300);
    reset({ email: "", otp: Array(6).fill("") });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOtpSent) {
      otpRefs.current[0]?.focus();
    }
  }, [isOtpSent]);

  useEffect(() => {
    if (isOtpSent && timer > 0) {
      const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(interval);
    }
  }, [isOtpSent, timer]);

  const handleOtpChange = (value, index) => {
    if (/^\d?$/.test(value)) {
      setValue(`otp.${index}`, value, { shouldValidate: true });
      if (value && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      } else if (!value && index > 0) {
        otpRefs.current[index - 1].focus();
      }
    }
  };

  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text").slice(0, 6);

    if (/^\d+$/.test(pastedData)) {
      pastedData.split("").forEach((char, i) => {
        setValue(`otp.${i}`, char, { shouldValidate: true });
        if (otpRefs.current[i]) {
          otpRefs.current[i].value = char;
        }
      });
      otpRefs.current[Math.min(pastedData.length, 5)].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpRefs.current[index - 1].focus();
    } else if (e.key === "Enter") {
      handleSubmit(isOtpSent ? verifyOtp : sendOtp)();
    }
  };

  const sendOtp = async (data) => {
    const response = await fetchData("user/login", {
      method: "post",
      body: JSON.stringify({ email: data.email }),
    });
    if (response?.code === 200) {
      setIsOtpSent(true);
      setTimer(300);
      enqueueSnackbar(response.message || "OTP sent successfully", {
        variant: "success",
      });
    }
  };

  const verifyOtp = async (data) => {
    for (let i = 0; i < data.otp.length; i++) {
      if (!data.otp[i]) {
        enqueueSnackbar("OTP must be 6 digits", { variant: "error" });
        return;
      }
    }

    if (data.otp.length !== 6) {
      enqueueSnackbar("OTP must be 6 digits", { variant: "error" });
      return;
    }

    const response = await fetchData("user/validateotp", {
      method: "post",
      body: JSON.stringify({ email: data.email, otp: data.otp.join("") }),
    });

    if (response?.code === 200) {
      dispatch(
        userSignIn({
          user: { email: data.email, uuid: response.data.userUuid },
          ssid: response.data.ssid,
        })
      );
      setIsOtpSent(false);
      setTimer(300);
      closeAuthModal();
      enqueueSnackbar(response?.message || "OTP verified successfully", {
        variant: "success",
      });
      reset();
    } else {
      enqueueSnackbar(response?.message || "Invalid OTP", {
        variant: "error",
      });
    }
  };

  const handleResendOtp = async () => {
    const response = await fetchData("user/login", {
      method: "post",
      body: JSON.stringify({ email: getValues("email") }),
    });
    if (response?.code === 200) {
      enqueueSnackbar(response.message || "OTP sent successfully", {
        variant: "success",
      });
      setTimer(300);
    }
  };

  return (
    <Dialog
      open={isAuthModalOpen}
      onClose={() => {
        setIsOtpSent(false);
        reset();
        closeAuthModal();
      }}
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: "16px",
          width: { xs: "90%", sm: "50%", md: "40%", lg: "25%" },
        },
      }}
      fullWidth
      aria-hidden={!isAuthModalOpen}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        py={1}
      >
        <Typography
          variant="h5"
          align="center"
          color="primary"
          sx={{ fontWeight: 600 }}
        >
          LOGIN / SIGN-UP
        </Typography>
        <IconButton
          sx={{ color: "black" }}
          onClick={() => {
            setIsOtpSent(false);
            reset();
            closeAuthModal();
          }}
        >
          <Icons.Close />
        </IconButton>
      </Stack>
      <Divider />

      <DialogContent>
        <Typography variant="h6" align="center" gutterBottom sx={{ mb: 2 }}>
          Login or Sign-up via OTP
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(isOtpSent ? verifyOtp : sendOtp)}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <TextField
            placeholder="Enter email address"
            fullWidth
            className="inputMdRounded"
            sx={{ bgcolor: "transparent" }}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ""}
            {...register("email", {
              required: !isOtpSent && "Email is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Enter a valid email",
              },
            })}
            slotProps={{
              input: {
                readOnly: isOtpSent,
              },
            }}
          />

          {isOtpSent && (
            <Stack direction="column" spacing={2} mt={2}>
              <Typography variant="h6" align="center">
                Enter OTP
              </Typography>
              <Stack direction="row" justifyContent="center" gap={1} pb={1}>
                {[...Array(6)].map((_, index) => (
                  <Controller
                    key={index}
                    name={`otp.${index}`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        inputRef={(el) => (otpRefs.current[index] = el)}
                        onChange={(e) => handleOtpChange(e.target.value, index)}
                        onPaste={index === 0 ? handleOtpPaste : null}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        slotProps={{ htmlInput: { maxLength: 1 } }}
                        sx={{
                          width: "4.5ch",
                          height: "3ch",
                          textAlign: "center",
                        }}
                      />
                    )}
                  />
                ))}
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography align="center" variant="body1" color="error">
                  {timer > 0
                    ? `0${Math.floor(timer / 60)}:${String(timer % 60).padStart(
                        2,
                        "0"
                      )}`
                    : ""}
                </Typography>
                <Button
                  disabled={timer > 0}
                  onClick={handleResendOtp}
                  sx={{
                    textAlign: "center",
                    textTransform: "none",
                    color: "primary.main",
                  }}
                >
                  Resend OTP
                </Button>
              </Stack>
            </Stack>
          )}
        </Box>
      </DialogContent>

      <DialogActions sx={{ px: 2, pb: 2 }}>
        <Button
          type="submit"
          onClick={handleSubmit(isOtpSent ? verifyOtp : sendOtp)}
          variant="contained"
          fullWidth
          sx={{ textDecoration: "none", py: 1.5, borderRadius: "8px" }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthModal;
