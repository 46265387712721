import * as React from "react";

export function Person(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={20}
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M10 10a3.21 3.21 0 01-2.354-.98 3.21 3.21 0 01-.98-2.354c0-.916.327-1.701.98-2.354A3.21 3.21 0 0110 3.333a3.21 3.21 0 012.354.98 3.21 3.21 0 01.979 2.353 3.21 3.21 0 01-.98 2.355A3.21 3.21 0 0110 10zm-6.667 6.666v-2.333c0-.472.122-.906.365-1.302a2.43 2.43 0 01.968-.906 12.37 12.37 0 012.625-.97A11.464 11.464 0 0110 10.834c.916 0 1.82.108 2.708.323a12.37 12.37 0 012.625.969c.403.208.726.51.969.906s.364.83.364 1.302v2.333H3.333zM5 15h10v-.667a.81.81 0 00-.417-.708 10.863 10.863 0 00-2.27-.844 9.667 9.667 0 00-4.626 0c-.764.187-1.52.469-2.27.844a.81.81 0 00-.417.708V15zm5-6.667c.458 0 .85-.163 1.177-.49.326-.326.49-.718.49-1.177 0-.458-.164-.85-.49-1.177A1.605 1.605 0 0010 5c-.459 0-.851.164-1.177.49-.327.327-.49.719-.49 1.177 0 .459.163.851.49 1.177.326.327.718.49 1.177.49z"
          fill="#319795"
        />
      </g>
    </svg>
  );
}
