import * as React from "react";

export function Facebook(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_498_1368)">
        <path
          d="M28 14c0-7.732-6.268-14-14-14S0 6.268 0 14c0 6.988 5.12 12.78 11.813 13.83v-9.783H8.258V14h3.555v-3.084c0-3.51 2.09-5.447 5.288-5.447 1.531 0 3.133.273 3.133.273v3.446H18.47c-1.74 0-2.282 1.079-2.282 2.186V14h3.883l-.62 4.047h-3.262v9.783C22.88 26.78 28 20.988 28 14z"
          fill="#1877F2"
        />
        <path
          d="M19.45 18.047L20.07 14h-3.883v-2.626c0-1.107.543-2.187 2.282-2.187h1.765V5.742S18.632 5.47 17.1 5.47c-3.197 0-5.288 1.938-5.288 5.447V14H8.258v4.047h3.555v9.783a14.104 14.104 0 004.374 0v-9.783h3.263z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_498_1368">
          <path fill="#fff" d="M0 0H28V28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
