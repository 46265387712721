/**
 * @file HomePage.jsx
 * @author Rishikesh
 * @date 2024-10-10
 * @description Home page of the app.
 */
import {
  Box,
  Card,
  CardActionArea,
  Divider,
  Grid2,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icons } from "../../../assets";
import { useFetchData } from "../../../components/hooks/useFetchData";
import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";
import CustomSkeleton from "../../../components/common/CustomSkeleton";

const HomePage = () => {
  const navigate = useNavigate();
  const padding = useResponsivePadding();
  const { fetchData, loading } = useFetchData();

  const [minLoading, setMinLoading] = useState(true);
  const [productList, setProductList] = useState([]);

  const getProductList = async () => {
    const result = await fetchData("user/products");
    if (result?.code === 200) {
      setProductList(result.data);
    }
  };

  React.useEffect(() => {
    const minLoadingTimer = setTimeout(() => {
      setMinLoading(false);
    }, 1500);

    return () => clearTimeout(minLoadingTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  React.useEffect(() => {
    getProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = minLoading || loading["user/products"];

  return (
    <Box
      flex={1}
      display="flex"
      px={padding}
      overflow={"hidden"}
      flexDirection="column"
      pb={5}
    >
      {/* Headline tags */}
      <Box width="100%" py={8} textAlign="center">
        <Typography
          fontSize={{ xs: "3rem", sm: "4rem", md: "5rem", lg: "6rem" }}
          fontWeight={700}
          color="#DC5A5A"
        >
          Your Imagination
        </Typography>
        <Typography
          fontSize={{ xs: "3rem", sm: "4rem", md: "5rem", lg: "6rem" }}
          fontWeight={700}
        >
          Our Canvas
        </Typography>
      </Box>
      {/* Listing of popular products */}
      <Box width="100%">
        <Typography variant="h5" fontWeight={600} mb={2}>
          Popular Products
        </Typography>
        <Grid2
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {isLoading
            ? Array.from(new Array(4)).map((_, index) => (
                <Grid2 key={index} size={{ xs: 2, sm: 3, md: 3 }}>
                  <CustomSkeleton
                    width="100%"
                    height={360}
                    iconHeight={238 / 2}
                    iconWidth={181 / 2}
                  />
                </Grid2>
              ))
            : productList.map((item, index) => (
                <Grid2 key={item.productuuid} size={{ xs: 2, sm: 3, md: 3 }}>
                  <Card
                    sx={{
                      minHeight: { sm: 300, md: 320, lg: 360 },
                      maxWidth: 290,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardActionArea
                      onClick={() =>
                        navigate(`/customize/${item.seourl}`, {
                          state: { product: item },
                        })
                      }
                    >
                      <Box
                        display="flex"
                        width="100%"
                        height={{ xs: 204, sm: 214, md: 224, lg: 234 }}
                        justifyContent="center"
                        alignItems="center"
                        mb={2}
                      >
                        {item.defaultimage ? (
                          <img
                            src={item.defaultimage}
                            alt={item.productname}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <Icons.Image />
                        )}
                      </Box>
                      <Divider />
                      <Box
                        flex={1}
                        display="flex"
                        height={{ xs: 50, sm: 50, md: 60, lg: 70 }}
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        mt={2}
                      >
                        <Typography fontWeight={500}>
                          {item.productname}
                        </Typography>
                      </Box>
                    </CardActionArea>
                  </Card>
                </Grid2>
              ))}
        </Grid2>
      </Box>
    </Box>
  );
};

export default HomePage;
