/**
 * @file Dashboard.jsx
 * @author Rishikesh
 * @date 2024-10-21
 * @description Dashboard for the admin to get the site update details
 */
import {
  Box,
  Button,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFetchData } from "../../../components/hooks/useFetchData";
import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";

const Dashboard = () => {
  const navigate = useNavigate();
  const padding = useResponsivePadding();
  const { fetchData, loading } = useFetchData();

  const [orders, setOrders] = useState([]);

  const listOrders = async () => {
    const result = await fetchData("admin/orders/listorders");
    if (result?.code === 200) {
      setOrders(result.data);
    }
  };

  React.useEffect(() => {
    listOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOrderClick = (id) => {
    navigate(`/admin/product-details/${id}`);
  };

  const isLoading = loading["admin/orders/listorders"];

  return (
    <Box px={padding} py={4}>
      <Typography variant="h4" gutterBottom>
        Ordered Products
      </Typography>
      {isLoading ? (
        <Box>
          {[...Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              height={40}
              sx={{ mb: 2 }}
            />
          ))}
        </Box>
      ) : orders.length === 0 ? (
        <Typography variant="body1" sx={{ mt: 2 }}>
          No orders available.
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Order ID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order, index) => (
                <TableRow key={order.orderuuid}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{order.orderuuid}</TableCell>
                  <TableCell>{order.email}</TableCell>
                  <TableCell
                    sx={{
                      color: order.paymentstatus === 1 ? "green" : "red",
                    }}
                  >
                    {order.paymentstatus === 1 ? "Paid" : "Unpaid"}
                  </TableCell>
                  <TableCell>
                    {new Date(order.createdon).toLocaleString("en-US", {
                      timeZone: "Asia/Dubai",
                    })}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleOrderClick(order.orderuuid)}
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default Dashboard;
