import * as React from "react";

export function OrderBox(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={20}
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M4.167 6.667v9.166h11.666V6.667h-2.5v6.666L10 11.667l-3.333 1.666V6.667h-2.5zm0 10.833c-.459 0-.851-.163-1.177-.49a1.605 1.605 0 01-.49-1.177V5.437c0-.194.031-.381.094-.562.062-.18.156-.347.281-.5l1.042-1.27c.152-.195.343-.345.573-.449.229-.104.468-.156.718-.156h9.584c.25 0 .49.052.718.156.23.104.42.254.573.448l1.042 1.271c.125.153.219.32.281.5.063.18.094.368.094.563v10.395c0 .459-.163.851-.49 1.177-.326.327-.718.49-1.177.49H4.167zM4.5 5h11l-.708-.833H5.208L4.5 5zm3.833 1.667v3.958L10 9.792l1.667.833V6.667H8.333z"
          fill="#319795"
        />
      </g>
    </svg>
  );
}
