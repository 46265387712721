/**
 * @file theme.js
 * @author Rishikesh
 * @date 2024-10-10
 * @description Theme of this app designed by Anoop Mohan (Obidos Technologies).
 */
import { createTheme } from "@mui/material/styles";

// Light theme
const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#122854",
    },
    secondary: {
      main: "#319795",
    },
    background: {
      default: "#F3F7FF",
      paper: "#FEFFFF",
    },
    text: {
      primary: "#202733",
      secondary: "#727984",
      tertiary: {
        main: "#575F6D",
        light: "#9EA3AB",
      },
    },
  },
  typography: {
    fontFamily: "Montserrat, Arial, sans-serif",
  },
  components: {
    MuiSkeleton: {
      defaultProps: {
        animation: "wave",
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#00000000",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#00000000",
            width: "0.4em",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: "12px",
            width: "5px",
            backgroundColor: "#D9D9D9",
            // minHeight: 24,
            border: "0px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#00000000",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          maxLength: 100,
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: "#FDFDFD",
          borderRadius: 4,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#FDFDFD",
        },
      },
    },
  },
});

export { lightTheme };
