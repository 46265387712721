/**
 * @file CustomSkeleton.jsx
 * @author Rishikesh
 * @date 2024-11-06
 * @description Custom skeleton component to show the loading state.
 */
import React from "react";
import { Box, Skeleton } from "@mui/material";

import { Icons } from "../../assets";

const CustomSkeleton = ({
  width = "80%",
  height = "100%",
  logoOpacity = 0.2,
  sx = {},
  iconWidth = 181,
  iconHeight = 238,
}) => (
  <Box position="relative" width={width} height={height} sx={sx}>
    {/* Stencil */}
    <Skeleton variant="rectangular" width="100%" height="100%" />
    {/* Watermark Logo */}
    <Box
      position="absolute"
      top="50%"
      left="50%"
      sx={{
        transform: "translate(-50%, -50%)",
        opacity: logoOpacity,
      }}
    >
      <Icons.Logo width={iconWidth} height={iconHeight} />
    </Box>
  </Box>
);

export default CustomSkeleton;
