/**
 * @file Footer.jsx
 * @author Rishikesh
 * @date 2024-10-11
 * @description Footer component of this app.
 */
import React from "react";
import {
  Box,
  Stack,
  Typography,
  Link,
  IconButton,
  Divider,
} from "@mui/material";
import { Icons, Images } from "../../assets";
import { useResponsivePadding } from "../hooks/useResponsivePadding";

const Footer = () => {
  const padding = useResponsivePadding();
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        color: "white",
        py: 4,
        textAlign: "center",
      }}
    >
      <Stack
        px={padding}
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems="start"
        spacing={3}
        pb={2}
      >
        <Stack direction="column" alignSelf="start">
          <img src={Images.logo} style={{ height: "60px" }} alt="logo" />
        </Stack>

        <Stack direction="column" alignItems="start">
          <Typography variant="h6">Important Links</Typography>
          <Stack direction="column" alignItems="start" spacing={1} mt={2}>
            <Link href="#" color="inherit" underline="hover">
              Privacy Policy
            </Link>
            <Link href="#" color="inherit" underline="hover">
              Terms and Conditions
            </Link>
            <Link href="#" color="inherit" underline="hover">
              Guidelines
            </Link>
          </Stack>
        </Stack>

        <Stack direction="column" alignItems="start">
          <Typography variant="h6">Contact Us</Typography>
          <Stack direction="column" alignItems="start" spacing={1} mt={2}>
            <Link href="#" color="inherit" underline="hover">
              logo@mail.com
            </Link>
            <Link href="#" color="inherit" underline="hover">
              +91 1234567890
            </Link>
          </Stack>
        </Stack>

        {/* Social Media Icons */}
        <Stack direction="column" alignItems="start" spacing={1}>
          <Typography variant="h6">Follow Us</Typography>
          <Stack direction="row" spacing={1} mt={2}>
            <IconButton color="inherit" href="#">
              <Icons.Facebook />
            </IconButton>
            <IconButton color="inherit" href="#">
              <Icons.Instagram />
            </IconButton>
            <IconButton color="inherit" href="#">
              <Icons.Whatsapp />
            </IconButton>
            <IconButton color="inherit" href="#">
              <Icons.Youtube />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ borderColor: "#43567D" }} />
      <Typography variant="body2" sx={{ mt: 2 }}>
        © 2024 All Rights Reserved - Logo Name
      </Typography>
    </Box>
  );
};

export default Footer;
