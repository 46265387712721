/**
 * @file cartSlice.js
 * @author Rishikesh
 * @date 2024-10-29
 * @description Cart slice management
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, { payload }) => {
      state.cart = [...state.cart, payload];
    },
    removeFromCart: (state, { payload }) => {
      state.cart = state.cart.filter((item) => item.orderitemuuid !== payload);
    },
    incrementQuantity: (state, { payload }) => {
      state.cart = state.cart.map((item) => {
        if (item.orderitemuuid === payload) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }
        return item;
      });
    },
    decrementQuantity: (state, { payload }) => {
      state.cart = state.cart.map((item) => {
        if (item.orderitemuuid === payload) {
          return {
            ...item,
            quantity: item.quantity - 1,
          };
        }
        return item;
      });
    },
    clearCart: (state) => {
      state.cart = [];
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
  clearCart,
} = cartSlice.actions;
export default cartSlice.reducer;
