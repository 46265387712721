/**
 * @file store.js
 * @author Rishikesh
 * @date 2024-10-10
 * @description Store managment of this app.
 */
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";

import authReducer from "./features/authSlice";
import cartReducer from "./features/cartSlice";
import settingsReducer from "./features/settingsSlice";
import tempImageReducer from "./features/tempImageSlice";

const persistConfig = {
  key: "pixo",
  storage,
  blacklist: ["cart", "tempImage"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  settings: settingsReducer,
  tempImage: tempImageReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
        ignoredActionPaths: ["meta.arg", "payload.timestamp"],
        ignoredPaths: ["auth.token"],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
