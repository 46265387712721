/**
 * @file AdminLayout.jsx
 * @author Rishikesh
 * @date 2024-10-21
 * @description Layout for admin
 */
import { Box, Toolbar } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

import AdminHeader from "../common/AdminHeader";

const AdminLayout = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      sx={{ "&::-webkit-scrollbar": { display: "none" } }}
    >
      <AdminHeader />
      <Box display="flex" flex={1}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Toolbar />
          <Box
            sx={{
              flex: 1,
              backgroundColor: "background.default",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminLayout;
