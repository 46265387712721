/**
 * @file CartPage.jsx
 * @author Rishikesh
 * @date 2024-10-30
 * @description Cart Page for user
 */
import {
  Box,
  Button,
  Card,
  Divider,
  Grid2,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { Icons } from "../../../assets";
import {
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../../store/features/cartSlice";
import { useFetchData } from "../../../components/hooks/useFetchData";
import { base64ToFile } from "../../../components/hooks/usebase64ToFile";
import { useResponsivePadding } from "../../../components/hooks/useResponsivePadding";
import { useUserAuthModal } from "../../../components/context/UserAuthModalContext";
import { BufferLoader } from "../../../components/common/BufferLoader";

const CartPage = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const padding = useResponsivePadding();
  const { fetchData, loading } = useFetchData();
  const { openAuthModal } = useUserAuthModal();

  const { cart } = useSelector((state) => state.cart);
  const user = useSelector((state) => state.auth.user);
  const currency = useSelector((state) => state.settings.currency);

  const grandTotal = cart.reduce((total, item) => {
    return total + (item.price || 0) * item.quantity;
  }, 0);

  const handleUploadImageFiles = async (item) => {
    if (!item) return;

    const image = cart.find((i) => i.orderitemuuid === item.orderitemuuid);
    let allImagesUploaded = true;

    for (const canvas of image.canvasDataArray) {
      const { canvasId, rowImage, imageData } = canvas;
      const imagesToUpload = [
        { base64Image: rowImage, imageType: "rawimage" },
        { base64Image: imageData, imageType: "userimage" },
      ];

      for (const { base64Image, imageType } of imagesToUpload) {
        if (!base64Image) continue;

        const fileName = `image_${item.orderitemuuid}_${canvasId}_${imageType}.png`;
        const imageFile = base64ToFile(base64Image, fileName);

        const formData = new FormData();
        formData.append("image", imageFile, fileName);
        formData.append("orderuuid", item.orderuuid);
        formData.append("orderitemuuid", item.orderitemuuid);
        formData.append("imagetype", imageType);
        formData.append("canvasnumber", canvasId);

        try {
          const result = await fetchData("user/upload-image", {
            method: "post",
            body: formData,
          });

          if (!(result?.code === 200 || result?.code === 201)) {
            enqueueSnackbar("Image upload failed", { variant: "error" });
            allImagesUploaded = false;
            break;
          }
        } catch (error) {
          enqueueSnackbar("Image upload error", { variant: "error" });
          allImagesUploaded = false;
          break;
        }
      }
    }

    if (allImagesUploaded) {
      dispatch(removeFromCart(item.orderitemuuid));
    }
    return allImagesUploaded;
  };

  const handleCheckout = async () => {
    if (!user.isAuthenticated) return openAuthModal();

    const updatedCart = cart.map((item) => ({
      ...item,
      canvasDataArray: [],
    }));

    const body = { orders: updatedCart };
    const orderResult = await fetchData("user/order", {
      method: "post",
      body: JSON.stringify(body),
    });

    if (orderResult?.code === 200 || orderResult?.code === 201) {
      let allUploadsSuccessful = true;

      if (Array.isArray(orderResult.data)) {
        for (const item of orderResult.data) {
          const uploadSuccess = await handleUploadImageFiles(item);
          if (!uploadSuccess) {
            allUploadsSuccessful = false;
            break;
          }
        }
      }

      if (allUploadsSuccessful) {
        // All images uploaded, proceed with payment API
        try {
          const paymentResult = await fetchData("user/create-payment-link", {
            method: "post",
            body: JSON.stringify({
              orderuuid: orderResult.data?.[0].orderuuid,
            }),
          });

          if (paymentResult?.code === 200 || paymentResult?.code === 201) {
            window.location.href = paymentResult?.data?.sessionUrl;
          } else {
            enqueueSnackbar("Payment failed", { variant: "error" });
          }
        } catch (error) {
          enqueueSnackbar("Payment error", { variant: "error" });
        }
      } else {
        enqueueSnackbar(
          "Checkout completed, but some images failed to upload",
          {
            variant: "warning",
          }
        );
      }
    } else {
      enqueueSnackbar("Checkout failed", { variant: "error" });
    }
  };

  const isLoading = loading["user/order"] || loading["user/upload-image"];

  return (
    <Box px={padding} py={4}>
      <Stack spacing={2}>
        <Typography variant="h4" fontWeight={700}>
          Shopping Cart
        </Typography>
        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={8} flex="auto">
            {/* if no items in cart */}
            {cart.length === 0 && (
              <Stack
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Typography variant="h6" fontWeight={600}>
                  No items in cart
                </Typography>
              </Stack>
            )}
            {cart?.map((item) => (
              <Card key={item.orderitemuuid} variant="outlined" sx={{ mb: 1 }}>
                <Stack
                  p={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Box>
                      {item.canvasDataArray.length !== 1 ? (
                        <Icons.Image />
                      ) : (
                        <img
                          src={item.canvasDataArray?.[0]?.imageData}
                          alt="preview"
                          style={{
                            width: 72,
                            height: 72,
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </Box>
                    <Stack spacing={0.5}>
                      <Typography variant="h6" fontWeight={600}>
                        {item.productname || "Product Name"}
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButton
                          onClick={() =>
                            dispatch(decrementQuantity(item.orderitemuuid))
                          }
                        >
                          <Icons.Minus />
                        </IconButton>
                        <Typography variant="body1" fontWeight={500}>
                          {item.quantity}
                        </Typography>
                        <IconButton
                          onClick={() =>
                            dispatch(incrementQuantity(item.orderitemuuid))
                          }
                        >
                          <Icons.Plus />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack alignItems="end" minWidth={100}>
                    <IconButton
                      color="error"
                      onClick={() =>
                        dispatch(removeFromCart(item.orderitemuuid))
                      }
                    >
                      <Icons.Delete />
                    </IconButton>
                    <Typography variant="h6" fontWeight={500}>
                      Total: {currency} {item.price * item.quantity}
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
            ))}
          </Grid2>
          <Grid2 xs={12} md={4}>
            <Box
              display="flex"
              flexDirection="column"
              minHeight={"50%"}
              maxHeight={"100%"}
              borderLeft={{ xs: 0, md: "1px solid #E0E0E0" }}
              sx={{
                bgcolor: "transparent",
                px: 2,
              }}
            >
              <Stack spacing={2} display="flex" flexDirection="column" flex={1}>
                <Stack display="flex" spacing={2} flex={1}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1" color="text.secondary">
                      Grand Total
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      {currency} {grandTotal.toFixed(2)}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1" color="text.secondary">
                      Discount
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      {currency} 0.00
                    </Typography>
                  </Stack>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={4}
                >
                  <Typography fontWeight={600}>Amount Payable</Typography>
                  <Typography fontWeight={600}>
                    {currency} {grandTotal.toFixed(2)}
                  </Typography>
                </Stack>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={cart.length === 0}
                  sx={{ borderRadius: "8px", textTransform: "none" }}
                  onClick={handleCheckout}
                >
                  Proceed to Pay
                </Button>
              </Stack>
            </Box>
          </Grid2>
        </Grid2>
      </Stack>
      <BufferLoader
        loading={isLoading}
        text="Please wait we are uploading your images. Do not refresh or close the browser until the uploading is completed."
      />
    </Box>
  );
};

export default CartPage;
