import * as React from "react";

export function ImageUpload(props) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.145 13.963l-.174-.025A2.45 2.45 0 016.47 9.07l1.504.087.409-1.45a4.92 4.92 0 019.635 1.787l-.185 2.052 2.08-.01h.003a1.213 1.213 0 01-.003 2.427H16.25v1.875h3.662a3.088 3.088 0 100-6.176h-.028a6.83 6.83 0 00-.006-1.297 6.795 6.795 0 00-13.3-1.167 4.324 4.324 0 00-.871 8.596v.044h3.043v-1.875H6.145z"
        fill="#646668"
      />
      <path
        d="M8.957 10.043a1 1 0 101.414 1.414l1.293-1.293V20.25a1 1 0 102 0V10.164l1.293 1.293a1 1 0 001.414-1.414l-3-3a.997.997 0 00-.704-.293h-.006a.997.997 0 00-.704.293l-3 3z"
        fill="#646668"
      />
    </svg>
  );
}
