import * as React from "react";

export function Logo(props) {
  return (
    <svg
      width={181}
      height={238}
      viewBox="0 0 181 238"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      //   make this to monochrome
      style={{ filter: "grayscale(100%)" }}
    >
      <path
        d="M93.013 51.844c-.324 7.637 5.273 13.463 8.113 15.422l6.895.734c3.245-.734 10.221-3.892 12.168-10.648 2.433-8.446-2.839-12.852-4.056-22.032S118.972 22.836 121 21c-15.818 9.914-27.58 21.297-27.987 30.844z"
        fill="#fff"
      />
      <path
        d="M97.013 51.156c-.313 7.963 5.085 14.037 7.822 16.078l6.649.766c3.129-.766 9.856-4.058 11.734-11.102 2.346-8.804-2.738-13.398-3.911-22.968-1.174-9.57 2.737-13.016 4.693-14.93-15.254 10.336-26.596 22.203-26.987 32.156z"
        fill="#531D0B"
      />
      <path
        d="M99.116 53.21c-.892 5.963 3.593 11.18 5.946 13.044.62.125 2.676.448 5.947.746 11.894-4.1 10.035-16.4 9.664-18.264-1.487 10.437-5.947 13.419-7.805 14.537 6.69-7.828 5.575-12.3 4.088-20.5s-.372-13.791.743-16.773c-4.46 5.218-4.088 12.673-2.973 19.755 1.115 7.081-1.487 11.181-3.717 13.418 2.602-8.573 1.487-13.791.744-16.773-.744 13.418-3.346 15.282-4.461 16.773-1.486-10.437 2.602-20.5 5.576-26.091-8.177 12.3-9.072 23.109-8.549 26.09-3.345-14.908 5.203-25.717 7.434-28.326-9.292 8.572-11.522 14.909-12.637 22.363z"
        fill="#D57C31"
      />
      <path
        d="M102.876 61c-1.953-3.091-4.062-12.02 3.124-23-1.562 3.339-5.468 10.758-3.124 23zM116.137 42c.428 6.222 1.284 11.278-5.137 21 2.568-3.111 8.133-7 5.137-21zM121.107 54.57c-1.627 3.756-2.66 6.956-9.223 10.752 2.392-1.064 6.717-1.708 9.223-10.751zM117.959 38.429c.777 2.428 2.099 8.742 1.166 14.571.778-2.429 1.944-7.69-1.166-14.571zM109.016 53c-.13-3.913.467-12.79 3.888-17-1.166 2.429-3.888 7.69-3.888 17zM106.54 44.072c.862-3.6 3.664-11.538 7.972-14.478-1.719 1.908-5.627 5.991-7.972 14.478zM116.234 40.54c-.878-1.489-2.211-5.2-.522-8.142-.377 1.393-1.338 4.484.522 8.141z"
        fill="#FBE0BB"
      />
      <path
        d="M98.947 1.33c-39.416 7.78-68.91 47.6-65.258 87.895a52.604 52.604 0 007.337 21.813c6.436 10.505 16.523 10.44 23.827.575 10.48-14.132 15.983-30.382 18.292-47.698a11.102 11.102 0 00-.73-6.724 11.07 11.07 0 00-4.494-5.045c-.344 2.577-.508 4.678-.917 6.73-1.72 9.044-2.768 18.268-5.65 27.017a63.66 63.66 0 01-5.126 11.161 134.638 134.638 0 01-3.193 5.334c-1.85 2.939-3.553 6.008-6.698 7.764a5.25 5.25 0 01-6.272-.328 12.138 12.138 0 01-3.275-5.975 51.125 51.125 0 01-1.867-16.758c1.736-18.383 10.58-33.763 22.517-47.255A63.22 63.22 0 0199.327 20.13a63.106 63.106 0 0137.416 1.832c20.502 7.32 33.57 24.735 33.505 44.628-.131 36.11-30.345 67.822-66.945 69.873.295-2.314.508-4.563.868-6.779 2.866-17.349 6.19-34.616 8.45-52.03.41-3.284.639-6.435.737-9.685l-13.215-1.444-15.41 88.043c-12.43-6.123-24.973-11.49-36.65-18.335a81.285 81.285 0 01-18.897-16.036 30.849 30.849 0 01-7.663-14.538 30.891 30.891 0 01.884-16.418c5.747-17.267 12.986-34.058 19.65-51.03.541-1.362 1.31-2.626 1.982-3.956-17.26 15.856-30.115 34.47-37.173 56.84-7.058 22.373-2.457 41.757 16.654 55.906 12.053 8.929 25.514 15.855 38.008 24.21 11.005 7.353 22.206 14.641 32.064 23.406 9.4 8.354 7.73 15.544-4.34 23.225 4.553-12.819 4.684-23.701-9.825-29.2-1.883 11.227-3.995 22.372-5.518 33.583-.557 4.152-2.44 5.712-6.19 6.467-21.862 4.366-42.3 1.559-60.739-11.769-1.522-1.1-5.01-2.61-6.55-3.677-.524-.246-.704-.476.246 1.461 1.392 3.184 2.145 6.943 4.356 9.372a30.872 30.872 0 009.907 6.878c16.67 7.665 34.144 8.206 52.059 5.712 21.632-3.053 35.601-15.117 43.609-35.076 4.241-10.604 3.685-21.092-4.422-28.708a20.993 20.993 0 01-5.846-8.732 25.117 25.117 0 01-.114-12.277c.36-1.986.704-4.022 1.162-5.991a4.024 4.024 0 012.981-2.692 16.185 16.185 0 011.637-.41 148.145 148.145 0 0016.114-3.595c31.966-9.52 55.826-36.98 57.742-68.61C182.71 22.7 146.47 0 112.85 0a72.072 72.072 0 00-13.903 1.33z"
        fill="url(#paint0_linear_767_6259)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_767_6259"
          x1={180.018}
          y1={119}
          x2={0}
          y2={119}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8C42" />
          <stop offset={0.418081} stopColor="#C44184" />
          <stop offset={0.666378} stopColor="#855399" />
          <stop offset={1} stopColor="#1492D1" />
        </linearGradient>
      </defs>
    </svg>
  );
}
