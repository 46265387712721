import * as React from "react";

export function Close(props) {
  return (
    <svg
      width={32}
      height={33}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.657 24.043a1.333 1.333 0 001.885-1.886L17.885 16.5l5.657-5.656a1.333 1.333 0 00-1.885-1.886L16 14.615l-5.657-5.657a1.333 1.333 0 10-1.886 1.886l5.657 5.656-5.657 5.657a1.333 1.333 0 101.886 1.886L16 18.386l5.657 5.657z"
        fill="#202733"
      />
    </svg>
  );
}
