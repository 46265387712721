import * as React from "react";

export function Whatsapp(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 14c0 7.732 6.268 14 14 14s14-6.268 14-14S21.732 0 14 0 0 6.268 0 14z"
        fill="#25D366"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9 9.012A6.882 6.882 0 0014 7c-3.85 0-7 3.15-7 7 0 1.225.35 2.45.963 3.5L7 21l3.675-.962c1.05.524 2.188.875 3.325.875 3.85 0 7-3.15 7-7 0-1.838-.788-3.588-2.1-4.9zM14 19.776c-1.05 0-2.1-.262-2.975-.787l-.175-.088-2.188.613.613-2.1-.175-.263a5.63 5.63 0 01-.875-3.062c0-3.15 2.625-5.775 5.775-5.775 1.575 0 2.975.612 4.113 1.662 1.137 1.138 1.662 2.537 1.662 4.113 0 3.062-2.537 5.687-5.775 5.687zm3.15-4.375c-.175-.088-1.05-.525-1.225-.525-.175-.088-.263-.088-.35.088-.087.175-.438.525-.525.7-.088.087-.175.087-.35.087-.175-.088-.7-.262-1.4-.875-.525-.438-.875-1.05-.963-1.225-.087-.175 0-.263.088-.35l.262-.263c.088-.087.088-.175.176-.262.087-.088 0-.175 0-.263 0-.087-.35-.962-.525-1.312-.088-.262-.263-.262-.35-.262h-.35c-.088 0-.263 0-.438.175s-.612.612-.612 1.487c0 .875.612 1.662.7 1.838.087.087 1.224 1.925 2.974 2.624 1.488.613 1.75.438 2.1.438.35 0 1.05-.438 1.138-.788.175-.437.175-.787.087-.787-.087-.438-.262-.438-.437-.525z"
        fill="#fff"
      />
    </svg>
  );
}
