/**
 * @file usebase64ToFile.js
 * @author Rishikesh
 * @date 2024-11-01
 * @description Hook used to conver base64 to file.
 */
export const base64ToFile = (base64String, filename) => {
  // Split the Base64 string into parts
  const [header, data] = base64String.split(",");

  // Decode the Base64 string
  const byteString = atob(data);

  // Create an array buffer to hold the binary data
  const arrayBuffer = new Uint8Array(byteString.length);

  // Fill the array buffer with the binary data
  for (let i = 0; i < byteString.length; i++) {
    arrayBuffer[i] = byteString.charCodeAt(i);
  }

  // Create a Blob from the array buffer
  const blob = new Blob([arrayBuffer], {
    type: header.split(":")[1].split(";")[0],
  });

  // Create a File from the Blob
  return new File([blob], filename, { type: blob.type });
};
