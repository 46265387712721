/**
 * @file BufferLoader.jsx
 * @author Rishikesh
 * @date 2024-11-07
 * @description Loader component to show the loading state with buffer progress.
 */
import React from "react";
import { Backdrop, Box, LinearProgress, Typography } from "@mui/material";

export const BufferLoader = ({
  loading = false,
  text,
  backdropColor = "rgba(0, 0, 0, 0.6)",
  linearProgressBar1Color = `white`,
  linearProgressBar2Color = `lightgrey05`,
  height = 6,
  width = "100%",
  maxWidth = 400,
  borderRadius = 5,
}) => {
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const progressRef = React.useRef(() => {});

  React.useEffect(() => {
    progressRef.current = () => {
      if (progress === 75) {
        setProgress(0);
        setBuffer(10);
      } else {
        setProgress(progress + 1);
        if (buffer < 100 && progress % 5 === 0) {
          const newBuffer = buffer + 1 + Math.random() * 10;
          setBuffer(newBuffer > 75 ? 75 : newBuffer);
        }
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <Backdrop
      open={loading}
      sx={{ zIndex: 1300, backgroundColor: backdropColor }}
    >
      <Box sx={{ width: width, maxWidth: maxWidth }}>
        <LinearProgress
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
          sx={{
            height: height,
            borderRadius: borderRadius,
            "& .MuiLinearProgress-bar": {
              backgroundColor: linearProgressBar2Color,
            },
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: linearProgressBar1Color,
            },
          }}
        />
        {text && (
          <Typography
            variant="body1"
            sx={{
              color: "white",
              textAlign: "center",
              my: 1,
            }}
          >
            {text}
          </Typography>
        )}
      </Box>
    </Backdrop>
  );
};
