import * as React from "react";

export function ZoomIn(props) {
  return (
    <svg
      fill="none"
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M13.61 4.046a11.198 11.198 0 00-5.115 0 5.97 5.97 0 00-4.45 4.45 11.198 11.198 0 000 5.114 5.97 5.97 0 004.45 4.45c1.682.394 3.433.394 5.115 0a5.97 5.97 0 004.45-4.45 11.196 11.196 0 000-5.115 5.97 5.97 0 00-4.45-4.45zm-5.457-1.46a12.698 12.698 0 015.8 0 7.47 7.47 0 015.567 5.567 12.698 12.698 0 010 5.8 7.463 7.463 0 01-1.488 3.019l3.498 3.498a.75.75 0 11-1.06 1.06l-3.498-3.498a7.463 7.463 0 01-3.02 1.488 12.698 12.698 0 01-5.8 0 7.47 7.47 0 01-5.566-5.568 12.698 12.698 0 010-5.8 7.47 7.47 0 015.567-5.566zm2.9 5.099a.75.75 0 01.75.75v1.867h1.867a.75.75 0 010 1.5h-1.867v1.868a.75.75 0 01-1.5 0v-1.867H8.434a.75.75 0 010-1.5h1.867V8.434a.75.75 0 01.75-.75z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  );
}
