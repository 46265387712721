import * as React from "react";

export function Bulb(props) {
  return (
    <svg
      width={56}
      height={56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.774 50.284c3.717 0 5.84-1.746 6.343-4.007H21.432c.503 2.261 2.625 4.007 6.342 4.007z"
        fill="#AAA"
      />
      <path
        d="M35.157 8.287s-9.512-2.69-14.155.987c-4.643 3.675-4.979 12.133-4.979 12.133.455 1.721 3.287 6.605 3.287 6.605.42.71 1.753 5.82 2.527 8.836H34.22c.673-2.213 3.383-10.228 3.737-11.052.454-1.06 1.229-3.124 1.62-3.902.941-1.874.907-3.526.418-5.466-1.056-4.188-1.924-5.229-4.838-8.143v.002z"
        fill="#FCEA2B"
      />
      <path
        d="M27.775 5.602c-6.636 0-12.833 5.252-12.833 11.884 0 .84-.205 3.493.947 3.493 0 0 1.86-6.086 6.503-9.76C27.035 7.542 34.463 8.32 34.463 8.32c-.516-1.59-4.316-2.721-6.685-2.721l-.003.002z"
        fill="#FEFEFE"
      />
      <path
        d="M28 36.726V27.44m4.642 0h-9.286m17.515-8.968c0 3.1-1.388 4.595-2.92 8.166-.39.912-3.275 10.407-3.275 10.407H21.322s-2.319-9.077-2.758-9.815c-1.843-3.11-3.437-5.375-3.437-8.758 0-7.11 5.763-12.872 12.872-12.872 7.11 0 12.872 5.763 12.872 12.872zM34.346 45.726c0 2.52-2.84 4.563-6.343 4.563s-6.343-2.043-6.343-4.563h12.686zM21.77 42.761l12.46-2.699M29.71 43.61l4.496-.919m-12.413-2.558l4.495-.918"
        stroke="#202733"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
