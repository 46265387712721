/**
 * @file AppLayout.jsx
 * @author Rishikesh
 * @date 2024-10-10
 * @description Layout that serves as the main structure of the app for future modifications.
 */
import React from "react";
import { Outlet } from "react-router-dom";

const AppLayout = () => {
  return <Outlet />;
};

export default AppLayout;
