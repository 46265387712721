import * as React from "react";

export function Instagram(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 14c0 7.732 6.268 14 14 14s14-6.268 14-14S21.732 0 14 0 0 6.268 0 14z"
        fill="#F00073"
      />
      <path
        d="M14 8.05h2.975c.7 0 1.05.176 1.312.263.35.175.613.263.875.525.263.263.438.525.525.875.088.263.175.613.263 1.313v5.95c0 .7-.175 1.05-.263 1.312-.175.35-.262.613-.525.875-.262.263-.525.438-.875.525-.262.088-.612.175-1.312.263h-5.95c-.7 0-1.05-.175-1.313-.263-.35-.175-.612-.262-.875-.525-.262-.262-.437-.525-.525-.875-.087-.262-.175-.612-.262-1.312V14v-2.975c0-.7.175-1.05.262-1.313.175-.35.263-.612.525-.875.263-.262.525-.437.875-.525.263-.087.613-.175 1.313-.262H14zm0-1.312h-2.975c-.788 0-1.313.175-1.75.35a3.82 3.82 0 00-1.313.875c-.437.438-.612.788-.875 1.313-.175.437-.262.962-.35 1.75v5.95c0 .787.175 1.312.35 1.75.175.437.438.875.875 1.312.438.438.788.613 1.313.875.437.175.962.263 1.75.35h5.95c.787 0 1.312-.175 1.75-.35a3.82 3.82 0 001.312-.875c.438-.437.613-.787.875-1.312.175-.438.263-.963.35-1.75V14v-2.975c0-.788-.175-1.313-.35-1.75a3.82 3.82 0 00-.875-1.313c-.437-.437-.787-.612-1.312-.875-.438-.175-.963-.262-1.75-.35H14z"
        fill="#fff"
      />
      <path
        d="M14 10.238a3.735 3.735 0 00-3.763 3.763c0 2.1 1.663 3.762 3.763 3.762 2.1 0 3.762-1.662 3.762-3.762S16.1 10.238 14 10.238zm0 6.213A2.446 2.446 0 0111.55 14c0-1.313 1.05-2.45 2.45-2.45 1.312 0 2.45 1.05 2.45 2.45 0 1.312-1.138 2.45-2.45 2.45zM17.85 11.026a.875.875 0 100-1.75.875.875 0 000 1.75z"
        fill="#fff"
      />
    </svg>
  );
}
