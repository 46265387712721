/**
 * @file MainLayout.jsx
 * @author Rishikesh
 * @date 2024-10-10
 * @description Layout of the app that contains the header and drawer while including the main component of the app.
 */
import React, { useState } from "react";
import { Box, Toolbar } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

import Header from "../common/Header";
import Footer from "../common/Footer";
import { useFetchData } from "../hooks/useFetchData";

const MainLayout = () => {
  const location = useLocation();
  const { fetchData } = useFetchData();
  const [menuList, setMenuList] = useState([]);

  const getMenuList = async () => {
    const result = await fetchData("user/menus");
    if (result?.code === 200) {
      setMenuList(result.data);
    }
  };

  React.useEffect(() => {
    getMenuList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      sx={{ "&::-webkit-scrollbar": { display: "none" } }}
    >
      <Header menuList={menuList} />
      <Box display="flex" flex={1}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Toolbar
            sx={{
              height:
                menuList.length !== 0 || location.pathname !== "/canvas"
                  ? "120px"
                  : "76px",
            }}
          />
          <Box
            sx={{
              flex: 1,
              backgroundColor: "background.default",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default MainLayout;
