/**
 * @file settingsSlice.js
 * @author Rishikesh
 * @date 2024-11-13
 * @description Settings slice management
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currency: "AED",
  theme: "light",
  language: "en",
  notifications: {
    email: true,
    sms: false,
    inApp: true,
  },
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    toggleTheme: (state) => {
      state.theme = state.theme === "light" ? "dark" : "light";
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    updateNotifications: (state, action) => {
      state.notifications = { ...state.notifications, ...action.payload };
    },
  },
});

export const { setCurrency, toggleTheme, setLanguage, updateNotifications } =
  settingsSlice.actions;
export default settingsSlice.reducer;
