/**
 * @file useFetchData.js
 * @author Rishikesh
 * @date 2024-10-10
 * @description Hook used to manage the api callings simple by retuning data , error and the loading state for the api callings.
 */
import { useState, useCallback } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { adminSignOut } from "../../store/features/authSlice";
import { useUserAuthModal } from "../context/UserAuthModalContext";

export const useFetchData = (baseUrl = process.env.REACT_APP_API_URL) => {
  const disptch = useDispatch();
  const { openAuthModal } = useUserAuthModal();

  const [error, setError] = useState({});
  const [loading, setLoading] = useState({});

  const adminSsid = useSelector((state) => state.auth.admin.ssid);
  const userSsid = useSelector((state) => state.auth.user.ssid);

  const fetchData = useCallback(
    async (apiname, { method = "get", body = null } = {}) => {
      const isAdminApi = apiname.startsWith("admin/");
      const apiUrl = `${baseUrl}${apiname}`;
      const isFormData = body instanceof FormData;
      const defaultHeaders = {
        Accept: "application/json",
        "Content-Type": isFormData ? "multipart/form-data" : "application/json",
        ...(isAdminApi ? { ssid: adminSsid } : { ssid: userSsid }),
      };

      setLoading((prev) => ({ ...prev, [apiname]: true }));
      setError((prev) => ({ ...prev, [apiname]: null }));

      try {
        // Handle GET and POST requests
        const response =
          method === "get"
            ? await axios.get(apiUrl, { headers: defaultHeaders })
            : await axios.post(apiUrl, body, { headers: defaultHeaders });
        return response.data;
      } catch (err) {
        setError((prev) => ({
          ...prev,
          [apiname]: err.response?.data || err.message,
        }));
        if (err?.response?.status === 401) {
          if (isAdminApi) {
            disptch(adminSignOut());
          } else {
            openAuthModal();
          }
        }
        return null;
      } finally {
        setLoading((prev) => ({ ...prev, [apiname]: false }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseUrl, adminSsid, userSsid]
  );

  return { fetchData, error, loading };
};
