import * as React from "react";

export function Replace(props) {
  return (
    <svg
      width={18}
      height={19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.617 2.864a.75.75 0 00-1.483-.227l-.46 3a.75.75 0 00.565.842l3.084.75a.75.75 0 10.355-1.458l-1.456-.354c2.02-1.47 4.804-1.316 6.55.43 1.938 1.938 1.914 5.157-.118 7.189s-5.25 2.056-7.19.118a.75.75 0 10-1.06 1.06c2.553 2.553 6.721 2.472 9.31-.117 2.59-2.59 2.67-6.758.118-9.311C11.558 2.511 8 2.328 5.42 4.146l.197-1.282z"
        fill="#202733"
      />
    </svg>
  );
}
