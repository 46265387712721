import { useMediaQuery } from "@mui/material";

export const useResponsivePadding = () => {
  const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const matchDownXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const padding = matchDownXS
    ? "20px"
    : matchDownSM
    ? "40px"
    : matchDownMD
    ? "60px"
    : "100px";

  return padding;
};
