/**
 * @file LoginPage.jsx
 * @author Rishikesh
 * @date 2024-10-21
 * @description Login page of the admin
 */
import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { useFetchData } from "../../../components/hooks/useFetchData";
import { adminSignIn } from "../../../store/features/authSlice";

const AnimatedBox = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: 400,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  animation: "fadeIn 1s ease-in-out",
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateY(-20px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
}));

const LoginPage = () => {
  const navigate = useNavigate();
  const { fetchData } = useFetchData();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await fetchData("admin/auth/adminlogin", {
      method: "post",
      body: JSON.stringify(formValues),
    });
    if (result?.code === 200) {
      setTimeout(() => {
        setLoading(false);
        enqueueSnackbar(result.message || "Successfull!", {
          variant: "success",
        });
        dispatch(
          adminSignIn({
            user: { username: formValues.username },
            ssid: result.data.sessionUUID,
          })
        );
        navigate("dashboard");
      }, 2000);
    } else {
      enqueueSnackbar(result?.message || "Invalid credentials", {
        variant: "error",
      });
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
      }}
    >
      <AnimatedBox component="form" onSubmit={handleSubmit}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Admin Login
        </Typography>
        <TextField
          label="Email"
          name="username"
          type="email"
          fullWidth
          margin="normal"
          variant="outlined"
          value={formValues.username}
          onChange={handleChange}
          required
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          fullWidth
          margin="normal"
          variant="outlined"
          value={formValues.password}
          onChange={handleChange}
          required
        />
        <Box sx={{ textAlign: "center", marginTop: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            sx={{ padding: "10px", fontSize: "16px" }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Login"}
          </Button>
        </Box>
      </AnimatedBox>
    </Box>
  );
};

export default LoginPage;
