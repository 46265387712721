/**
 * @file AdminRoute.jsx
 * @author Rishikesh
 * @date 2024-10-25
 * @description Route for admin
 */
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AdminRoute = () => {
  const isAdminAuthenticated = useSelector(
    (state) => state.auth.admin?.isAuthenticated
  );

  return isAdminAuthenticated ? <Outlet /> : <Navigate to="/admin" />;
};

export default AdminRoute;
