import * as React from "react";

export function Youtube(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 14c0 7.732 6.268 14 14 14s14-6.268 14-14S21.732 0 14 0 0 6.268 0 14z"
        fill="red"
      />
      <path
        d="M20.65 10.587a1.733 1.733 0 00-1.225-1.225c-1.05-.262-5.513-.262-5.513-.262s-4.374 0-5.512.262a1.733 1.733 0 00-1.225 1.225C7 11.725 7 14 7 14s0 2.275.263 3.412c.175.613.612 1.05 1.225 1.225C9.538 18.9 14 18.9 14 18.9s4.375 0 5.512-.263a1.733 1.733 0 001.226-1.225C21 16.275 21 14 21 14s0-2.275-.35-3.413zM12.6 16.1v-4.2l3.675 2.1-3.675 2.1z"
        fill="#fff"
      />
    </svg>
  );
}
