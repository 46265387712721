/**
 * @file DrawerContext.js
 * @author Rishikesh
 * @date 2024-10-10
 * @description Component used to manage the drawer and get the drawer state in whole app.
 */
import React, { createContext, useState, useContext } from "react";

const DrawerContext = createContext();

export const useDrawer = () => {
  return useContext(DrawerContext);
};

export const DrawerProvider = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  return (
    <DrawerContext.Provider value={{ isDrawerOpen, toggleDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};
