import * as React from "react";

export function Cart(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={20}
        height={20}
      >
        <path fill="#D9D9D9" d="M0 0H20V20H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M5.833 18.334c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 01-.49-1.177c0-.458.164-.85.49-1.177.326-.326.719-.49 1.177-.49.458 0 .85.164 1.177.49.326.326.49.719.49 1.177 0 .458-.164.85-.49 1.177-.326.326-.719.49-1.177.49zm8.333 0c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 01-.49-1.177c0-.458.164-.85.49-1.177.327-.326.719-.49 1.177-.49.459 0 .851.164 1.177.49.327.326.49.719.49 1.177 0 .458-.163.85-.49 1.177-.326.326-.718.49-1.177.49zM5.125 5l2 4.167h5.833L15.25 5H5.125zm-.792-1.666h12.292c.32 0 .562.142.729.427a.84.84 0 01.02.864L14.417 9.96a1.67 1.67 0 01-.614.646 1.62 1.62 0 01-.844.229H6.75L5.833 12.5h10v1.667h-10c-.625 0-1.097-.274-1.417-.823-.32-.548-.333-1.094-.041-1.635L5.5 9.667l-3-6.333H.833V1.667h2.708l.792 1.667z"
          fill="#319795"
        />
      </g>
    </svg>
  );
}
