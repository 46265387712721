import * as React from "react";

export function AlignCenter(props) {
  return (
    <svg
      fill="none"
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M11.583 2.277c.277-.036.557-.036.834 0a2.691 2.691 0 012.338 2.668v.876a6.787 6.787 0 013.424 3.424h.876c1.35 0 2.49 1 2.668 2.338.036.277.036.557 0 .834a2.691 2.691 0 01-2.668 2.338h-.876a6.787 6.787 0 01-3.424 3.424v.876c0 1.35-1 2.49-2.338 2.668a3.184 3.184 0 01-.834 0 2.691 2.691 0 01-2.338-2.668v-.876a6.787 6.787 0 01-3.424-3.424h-.876c-1.35 0-2.49-1-2.668-2.338l.5-.066-.5.066a3.176 3.176 0 010-.834l.744.099-.744-.099a2.691 2.691 0 012.668-2.338h.876a6.787 6.787 0 013.424-3.424v-.876c0-1.35 1-2.49 2.338-2.668zm.637 1.487a1.677 1.677 0 00-.44 0 1.191 1.191 0 00-1.035 1.181v1.383a.75.75 0 01-.5.707 5.281 5.281 0 00-3.21 3.21.75.75 0 01-.707.5H4.945c-.597 0-1.102.443-1.18 1.035-.02.146-.02.294 0 .44a1.191 1.191 0 001.18 1.035h1.383c.318 0 .601.2.707.5a5.281 5.281 0 003.21 3.21c.3.106.5.389.5.707v1.383c0 .597.443 1.102 1.035 1.18.146.02.294.02.44 0a1.191 1.191 0 001.035-1.18v-1.383c0-.318.2-.601.5-.707a5.281 5.281 0 003.21-3.21c.106-.3.389-.5.707-.5h1.383c.597 0 1.102-.443 1.18-1.035.02-.146.02-.294 0-.44a1.191 1.191 0 00-1.18-1.035h-1.383a.75.75 0 01-.707-.5 5.281 5.281 0 00-3.21-3.21.75.75 0 01-.5-.707V4.945c0-.597-.443-1.102-1.035-1.18z"
        fill="#000"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.75 12a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0zM12 10.25a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  );
}
