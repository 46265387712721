import * as React from "react";

export function Image(props) {
  return (
    <svg
      width={71}
      height={71}
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59.167 66.563H11.833V4.437h32.542L59.167 19.23v47.334z"
        fill="#90CAF9"
      />
      <path d="M56.948 20.708H42.896V6.656l14.052 14.052z" fill="#E1F5FE" />
      <path
        d="M31.063 34.02L20.707 48.813h20.709L31.062 34.022z"
        fill="#1565C0"
      />
      <path
        d="M41.417 39.05l-7.396 9.763h14.791l-7.395-9.763zM46.594 38.458a2.219 2.219 0 100-4.437 2.219 2.219 0 000 4.437z"
        fill="#1976D2"
      />
    </svg>
  );
}
