/**
 * @file PaymentStatus.jsx
 * @author Rishikesh
 * @date 2024-11-13
 * @description Page to display the payment status of the user.
 */
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useFetchData } from "../../../components/hooks/useFetchData";

const PaymentStatus = () => {
  const { orderstatus, orderuuid } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { fetchData } = useFetchData();

  const handleRetry = async () => {
    const paymentResult = await fetchData("user/create-payment-link", {
      method: "post",
      body: JSON.stringify({ orderId: orderuuid }),
    });

    if (paymentResult?.code === 200 || paymentResult?.code === 201) {
      window.location.href = paymentResult.data.sessionUrl;
    } else {
      enqueueSnackbar("Payment failed", { variant: "error" });
    }
  };
  return (
    <Box display="flex" flexDirection="column" alignItems="center" py={4}>
      <Box
        width={{ xs: "100%", md: "50%" }}
        mx="auto"
        mb={4}
        bgcolor="background.paper"
        p={4}
        borderRadius={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <iframe
          title="Lottie"
          src={
            orderstatus === "success"
              ? "https://lottie.host/embed/2da37ba0-ae02-4a16-bdd9-bea8809f08dd/joIwOKhS16.json"
              : "https://lottie.host/embed/02753a11-a05a-4415-8624-9dbc20e74ccb/cWAQWlWnZz.json"
          }
          frameBorder="0"
          style={{ width: "100%", height: "100%" }}
        />
        <Typography
          variant="h4"
          fontWeight={600}
          gutterBottom
          color={orderstatus === "success" ? "success.main" : "error.main"}
        >
          {orderstatus === "success" ? "Order Placed" : "Order Failed!"}
        </Typography>
        <Typography variant="body1" color="text.tertiary.light" gutterBottom>
          {orderstatus === "success"
            ? "Your order has been placed succesfully"
            : "There is a problem with placing your order"}
        </Typography>
        {orderstatus === "success" && (
          <Typography variant="body1" gutterBottom>
            Order ID : {orderuuid}
          </Typography>
        )}
        {orderstatus === "success" ? (
          <>
            <Button
              variant="contained"
              color="secondary"
              sx={{ my: 2, py: 2, textTransform: "none" }}
              onClick={() => navigate("/orders", { replace: true })}
            >
              Go to My Orders
            </Button>
            <Button
              sx={{ textTransform: "none" }}
              onClick={() => navigate("/", { replace: true })}
            >
              Continue Customizing
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 2, py: 2, textTransform: "none" }}
            onClick={handleRetry}
          >
            Retry
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default PaymentStatus;
